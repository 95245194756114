import React from "react";
import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useNavigate, useLocation } from "react-router-dom";

import Container from "../components/UI/Container";
import List from "../components/UI/List";
import Section from "../components/UI/Section";
import Footer from "../components/UI/Footer";

import lecture from "../assets/lecture.jpg";
import publications from "../assets/publications.png";
import equation1 from "../assets/equation1.png";
import equation2 from "../assets/equation2.png";
import fieldwork from "../assets/fieldwork.jpg";

import logo from "../assets/logo-f.svg";

import classes from "../components/UI/Header.module.css";

import { MdAssuredWorkload, MdAssignment } from "react-icons/md";
import { HiAcademicCap } from "react-icons/hi2";
import { ImBriefcase } from "react-icons/im";
import { RiExternalLinkLine } from "react-icons/ri";

const profileDescription = (
  <section
    style={{
      display: "flex",
      flexDirection: "column",
      flexWrap: "wrap",
      alignItems: "center",
      width: "100%",
    }}
  >
    <ul style={{ listStyleType: "none" }}>
      <li>
        <Section
          titleText="Scientist, Founder and Research Head, SMIT Enviro Solutions"
          subTitleText=""
          description={[
            <a
              href="https://www.smitenvirosolutions.in"
              target="_blank"
              rel="noreferrer"
            >
              www.smitenvirosolutions.in
              <RiExternalLinkLine style={{ margin: "5px 5px 0px" }} />
            </a>,
          ]}
        />
      </li>

      <li>
        <List
          icon={<ImBriefcase />}
          titleText="Professional Experience (40+ years)"
          list={[
            <a
              href="https://www.tropmet.res.in/"
              target="_blank"
              rel="noreferrer"
            >
              Senior Scientific Officer, Indian Institute of Tropical
              Meteorology
            </a>,
            <a href="https://www.cdac.in/" target="_blank" rel="noreferrer">
              Research Consultant, Center for Development of Advanced Computing
            </a>,
          ]}
        />
      </li>
      <li>
        <List
          icon={<HiAcademicCap />}
          titleText="Education"
          list={[
            "MSc (Physics), Nagpur University",
            "PhD (Physics), Pune University",
            "Post-doctoral research, National University Ireland, Galway",
          ]}
        />
      </li>
      <li>
        <List
          icon={<MdAssuredWorkload />}
          titleText="Research"
          list={[
            "Atmospheric Processes",
            "Climate Change and Associated Hazards",
            "Thunderstorm Dynamics in Extreme Weather",
            "Aerosol Cloud Interaction",
            "Cloud and Precipitation Processes",
            "Assessment of Environmental Impact on Human Health and Ecology",
            "Tropospheric Chemical Processes",
          ]}
        />
      </li>
      <li>
        <List
          icon={<MdAssignment />}
          titleText="Deputations"
          list={[
            <article>
              <a
                href="https://www.unesco.org/fr"
                target="_blank"
                rel="noreferrer"
              >
                UNESCO
                <RiExternalLinkLine style={{ margin: "5px 5px 0px" }} />
              </a>
              {" (France)"}
            </article>,
            <article>
              <a href="https://www.ceh.ac.uk/" target="_blank" rel="noreferrer">
                Centre of Ecology & Hydrology
                <RiExternalLinkLine style={{ margin: "5px 5px 0px" }} />
              </a>
              {" (UK)"}
            </article>,
            <article>
              <a href="https://ncar.ucar.edu/" target="_blank" rel="noreferrer">
                NSF National Center For Atmospheric Research
                <RiExternalLinkLine style={{ margin: "5px 5px 0px" }} />
              </a>
              {" (US)"}
            </article>,
            <article>
              <a href="https://www.epa.gov/" target="_blank" rel="noreferrer">
                US Environmental Protection Agency
                <RiExternalLinkLine style={{ margin: "5px 5px 0px" }} />
              </a>
              {" (US)"}
            </article>,
            <article>
              <a href="https://www.esa.int/" target="_blank" rel="noreferrer">
                ESA Centre for Earth Observation
                <RiExternalLinkLine style={{ margin: "5px 5px 0px" }} />
              </a>
              {" (Italy)"}
            </article>,
            <article>
              <a
                href="https://www.arl.noaa.gov/about/workshops/iwaqfr-home/"
                target="_blank"
                rel="noreferrer"
              >
                International Workshop on Air Quality Forecasting Research
                <RiExternalLinkLine style={{ margin: "5px 5px 0px" }} />
              </a>
              {" (Chile)"}
            </article>,
            <article>
              <a
                href="https://www.icimod.org/"
                target="_blank"
                rel="noreferrer"
              >
                International Centre for Integrated Mountain Development
                <RiExternalLinkLine style={{ margin: "5px 5px 0px" }} />
              </a>
              {" (Nepal)"}
            </article>,
            <article>
              <a
                href="https://www.columbia.edu/"
                target="_blank"
                rel="noreferrer"
              >
                Columbia University
                <RiExternalLinkLine style={{ margin: "5px 5px 0px" }} />
              </a>
              {" (US)"}
            </article>,
            <article>
              <a href="https://www.who.int/" target="_blank" rel="noreferrer">
                World Health Organization
                <RiExternalLinkLine style={{ margin: "5px 5px 0px" }} />
              </a>
              {" assignment at Lumbini, Nepal"}
            </article>,
          ]}
        />
      </li>
    </ul>
  </section>
);

const researchDescription = (
  <section
    style={{
      display: "flex",
      flexDirection: "column",
      flexWrap: "wrap",
      alignItems: "center",
      width: "100%",
    }}
  >
    <Section
      titleText="Indian Institute of Tropical Meteorology"
      subTitleText="Senior Scientist (1993-2019)"
      description={[
        "Indian Institute of Tropical Meteorology is a weather and climate research institution in Pune, India specializing in monsoon meteorology and air-sea interactions in the tropical South Asian region, particularly over the Indian Ocean. It is an autonomous institute under the Ministry of Earth Sciences, Government of India.",
        <a href="https://www.tropmet.res.in" target="_blank" rel="noreferrer">
          www.tropmet.res.in
          <RiExternalLinkLine style={{ margin: "5px 5px 0px" }} />
        </a>,
      ]}
    />
    <Section
      titleText="Centre for Development of Advanced Computing"
      subTitleText="Research Consultant (2019-2023)"
      description={[
        "The Centre for Development of Advanced Computing is a premier research and development organization under the Ministry of Electronics and Information Technology specializing in research in information technology, electronics, and associated areas.",
        <a href="https://www.cdac.in" target="_blank" rel="noreferrer">
          www.cdac.in
          <RiExternalLinkLine style={{ margin: "5px 5px 0px" }} />
        </a>,
      ]}
    />
    <Section titleText="Projects" subTitleText="" description={[]} />
    <Section
      titleText=""
      subTitleText="Parameterization of air pollution dispersion and hurricane prediction models"
      description={[
        <a
          href="https://www.sciencedirect.com/science/article/abs/pii/S0169809510003479"
          target="_blank"
          rel="noreferrer"
        >
          {"Read full article (www.sciencedirect.com)"}
          <RiExternalLinkLine style={{ margin: "5px 5px 0px" }} />
        </a>,
        <img src={equation1} alt="equation1" style={{ maxWidth: "90%" }} />,
      ]}
    />
    <Section
      titleText=""
      subTitleText="Air pollution dispersion models for sulfate, nitrate and PM₁₀ particle distributions"
      description={[
        <a
          href="https://agupubs.onlinelibrary.wiley.com/doi/10.1029/2005JD006406"
          target="_blank"
          rel="noreferrer"
        >
          {"Read full article (agupubs.onlinelibrary.wiley.com)"}
          <RiExternalLinkLine style={{ margin: "5px 5px 0px" }} />
        </a>,
        <a
          href="https://www.sciencedirect.com/science/article/abs/pii/S0169809510003479"
          target="_blank"
          rel="noreferrer"
        >
          {"Read full article (www.sciencedirect.com)"}
          <RiExternalLinkLine style={{ margin: "5px 5px 0px" }} />
        </a>,
      ]}
    />
    <Section
      titleText=""
      subTitleText="Hurricane forecasting models"
      description={[
        <a
          href="https://journalofweathermodification.org/index.php/JWM/article/view/152"
          target="_blank"
          rel="noreferrer"
        >
          {"Read full article (journalofweathermodification.org)"}
          <RiExternalLinkLine style={{ margin: "5px 5px 0px" }} />
        </a>,
        <img src={equation2} alt="equation2" style={{ maxWidth: "70%" }} />,
      ]}
    />
    <Section
      titleText=""
      subTitleText="Fieldwork"
      description={[
        "• Measurement of aerosol distribution changes during thunderstorm rain",
        "• Measurement of greenhouse gases, cloud condensation nuclei, and aerosols on-board ORV cruises Sagar Kanya and Sagar Nidhi in the Arabian Sea and Bay of Bengal",
        "• WHO assignment for air quality assessment at Lumbini (Nepal)",
        "• Flux measurements of greenhouse gases (CO₂, O₃, and water vapor) at the North Atlantic Ocean Coast (Mace Head)",
        "• Winter Fog Experiment (WiFEX) at Indira Gandhi International Airport, Delhi, a joint collaboration of IMD, ICAR, Central Agriculture University, and IISER.",
      ]}
    />
    <hr style={{ width: "97%" }}></hr>
    <Section
      titleText="Research Affiliations"
      subTitleText="Research grants awarded for sponsored scientific projects"
      description={[
        "•  Continental Tropical Convergence Zone (CTCZ) - Principal Investigator",
        "•  Indian Space Research Organization",
        "•  Council for Scientific and Industrial Research",
        "•  INTEL",
        "•  Ministry Sponsored Research Project, Deputy Project Director, SAFAR (MoES)",
        "•  Modelling Air Pollution And Networking (MAPAN)",
      ]}
    />
    <Section
      titleText=""
      subTitleText="Scientific symposia/conference/workshop"
      description={[
        <span style={{ fontWeight: "500" }}>SOLAS workshop </span>,
        "National University of Ireland, Galway, Ireland and CEH, Edinburgh, UK",
      ]}
    />
    <Section
      titleText=""
      subTitleText="International research proposal reviewer"
      description={[
        "Projects:",
        "•  Sources and pollutant binding of Asian Brown Cloud aerosols",
        "•  Satellite observations for studying aerosol effects on climate",
        "Funding Agency:",
        "•  Netherlands Organization for Scientific Research",
      ]}
    />
    <Section
      titleText=""
      subTitleText="International Scientific Seminar/Lectures"
      description={[
        "• Rain-air pollutant interactions, 8th July, 2010, University of Birmingham, UK.",
        "• Overview of pollutants deposition over forests, 4th June, 2010, Helsinki, Finland",
        "• Acid-rain and its impact on ecology, 5th November, 2008, at CEH Edinburgh, UK",
      ]}
    />
    <hr style={{ width: "97%" }}></hr>
    <Section
      titleText="Academic Positions"
      subTitleText=""
      description={[
        <span>
          <span style={{ fontWeight: "500" }}>Research Advisor: </span>
          Nan Yang Academy of Sciences, Singapore
        </span>,
        <span>
          <span style={{ fontWeight: "500" }}>Member: </span>
          Board of Studies, Environmental Sciences, SP Pune University, India
        </span>,
        <span>
          <span style={{ fontWeight: "500" }}>Member: </span>
          Board of Studies, Atmospheric and Space Technology, SP Pune University
        </span>,
        <span>
          <span style={{ fontWeight: "500" }}>Member: </span>
          Recruitment and Assessment Board, Council of Scientific and Industrial
          Research, India
        </span>,
        <span>
          <span style={{ fontWeight: "500" }}>Member: </span>
          SET Examination Board, Maharashtra and Goa
        </span>,
        <span>
          <span style={{ fontWeight: "500" }}>Visiting Faculty: </span>
          Department of Environmental Sciences, SP Pune University
        </span>,
        <a
          href="http://www.unipune.ac.in/dept/science/environmental_science/es_webfiles/faculty.htm"
          target="_blank"
          rel="noreferrer"
        >
          {"(www.unipune.ac.in)"}
          <RiExternalLinkLine style={{ margin: "5px 5px 0px" }} />
        </a>,
        <span>
          <span style={{ fontWeight: "500" }}>Adjunct Professor: </span>
          {
            "Masters Program at University of Hyderabad (UoH) and M. Tech. Atmospheric Sciences program at SP Pune University (SPPU)"
          }
        </span>,
        <span>
          <span style={{ fontWeight: "500" }}>Academic Guide: </span>
          {"for students of Summer Research Fellowship Program (SRFP) "}
          <a
            href="https://webjapps.ias.ac.in/fellowship2023/"
            target="_blank"
            rel="noreferrer"
          >
            {"(ias.ac.in/fellowship2023/)"}
            <RiExternalLinkLine style={{ margin: "5px 5px 0px" }} />
          </a>
          {
            " in a program jointly sponsored by the Indian National Science Academy "
          }
          <a href="https://www.ias.ac.in/" target="_blank" rel="noreferrer">
            {"(www.ias.ac.in/)"}
            <RiExternalLinkLine style={{ margin: "5px 5px 0px" }} />
          </a>
          {" and the Indian Academy of Sciences "}
          <a
            href="https://www.insaindia.res.in"
            target="_blank"
            rel="noreferrer"
          >
            {"(www.insaindia.res.in)"}
            <RiExternalLinkLine style={{ margin: "5px 5px 0px" }} />
          </a>
        </span>,
        <span>
          <span style={{ fontWeight: "500" }}>Research Guide: </span>
          {
            "for 5 Ph.D. and 70 B.E./M. Sc./M. Tech. students. (Programs/Institutes: SRFP, Indian Institutes of Technology, Indian Institutes of Science Education and Research, SPPU, Visvesvaraya Technological University, Bhartiya Vidyapeeth, and Vasantdada Sugar Institute)"
          }
        </span>,
        <span>
          <span style={{ fontWeight: "500" }}>Resource Faculty: </span>
          {
            "Refresher Courses for Faculty Development Program of SPPU, Mumbai University and Mahatma Phule Agriculture University, Rahuri, Maharashtra"
          }
        </span>,
        <span>
          <span style={{ fontWeight: "500" }}>Lecturer: </span>
          {
            "Training sessions at SPPU, Indian Meteorological Department, UoH, Mahindra International School, and Symbiosis International School"
          }
        </span>,
        <span>
          <span style={{ fontWeight: "500" }}>Editorial Board: </span>
        </span>,
        <span>
          {"The Scientific World Journal "}
          <a
            href="https://www.hindawi.com/journals/tswj/"
            target="_blank"
            rel="noreferrer"
          >
            {"(www.hindawi.com)"}
            <RiExternalLinkLine style={{ margin: "5px 5px 0px" }} />
          </a>
        </span>,
        <span>
          {"International Journal of Geology, Earth & Environmental Sciences "}
          <a href="http://www.cibtech.org/" target="_blank" rel="noreferrer">
            {"(http://www.cibtech.org/)"}
            <RiExternalLinkLine style={{ margin: "5px 5px 0px" }} />
          </a>
        </span>,
        <span>
          {"Advances in Meteorology "}
          <a
            href="https://www.hindawi.com/journals/amet/si/528693/cfp/"
            target="_blank"
            rel="noreferrer"
          >
            {"(www.hindawi.com)"}
            <RiExternalLinkLine style={{ margin: "5px 5px 0px" }} />
          </a>
        </span>,
        <span>
          <span style={{ fontWeight: "500" }}>Deputations: </span>
        </span>,
        "University of Columbia, NY, USA, November 2018",
        "TF-HTAP, US-EPA, North Carolina, USA, April 2017",
        "CFCC, UNESCO, Paris, July 2015",
        "IWRA, NCAR, Boulder, USA, July 2013",
        "IWAQFR, Santiago, Chile, October 2013",
        "ESRIN, Frascati, Italy, December 2013",
        "ICIMOD, Kathmandu/Pokhara, Nepal, July 2014",
        "WHO, Lumbini Protected Zone, Lumbini, Nepal, May 2012",
        "Post-Doctoral Researcher: NUI, Galway, Ireland/CEH, Edinburgh UK (2008-2009)",
      ]}
    />
  </section>
);

const publicationsDescription = (
  <section>
    <Section
      titleText="Air Pollution, Health, and Crop Impact Assessments"
      subTitleText=""
      description={[
        "The global count of premature deaths resulting from exposure to outdoor air pollution is in millions and is projected to steadily increase. The health and socioeconomic impacts of air pollution certainly cannot be undermined and the resultant health expenditures and productivity losses make air pollution a severe global threat requiring immediate attention and mitigation strategies.",
        "Statistics from research on socioeconomic and health-related impacts of air pollution:",
        "• Global premature mortality resulting from exposure to air pollution is projected to increase from approximately 3 million, as recorded in 2010, to 6–9 million by 2060.",
        "• Market impacts of outdoor air pollution are projected to gradually rise from the documented value of 0.3% of the global GDP in 2015 to 1% by 2060.",
        "• Premature mortalities due to PM₂.₅ and O₃ exposure in India have an economic cost of approximately 640 billion USD.",
        "• Crop damage due to O₃ exposure incurs an expenditure of 1.29 billion USD annually, an amount that would be sufficient to feed approximately 94 million people living in poverty.",
      ]}
    />
    <Section
      titleText=""
      subTitleText="2024"
      description={[
        <span>
          Dilip Chate et al.,{" "}
          <span style={{ fontStyle: "italic" }}>
            Temporal Variability of Ozone and its Precursors at Tropical
            Megacity, Bengaluru, India: Effect of Volatile Organic Compounds and
            Meteorology,
            <span style={{ fontWeight: "500" }}>
              {" "}
              Journal of Atmospheric and Solar-Terrestrial Physics,
            </span>
          </span>{" "}
          265:106388, 2024
        </span>,
        <a
          href="https://doi.org/10.1016/j.jastp.2024.106388"
          target="_blank"
          rel="noreferrer"
        >
          {"Article Link"}
          <RiExternalLinkLine style={{ margin: "5px 5px 0px" }} />
        </a>,
        <span>
          Dilip Chate et al.,{" "}
          <span style={{ fontStyle: "italic" }}>
            Effectiveness of Respiratory Face Masks in Reducing Acute PM₂.₅
            Pollution Exposure During Peak Pollution Period in Delhi,
            <span style={{ fontWeight: "500" }}>
              {" "}
              Science of The Total Environment,
            </span>
          </span>{" "}
          943:173787, 2024
        </span>,
        <a
          href="https://doi.org/10.1016/j.scitotenv.2024.173787"
          target="_blank"
          rel="noreferrer"
        >
          {"Article Link"}
          <RiExternalLinkLine style={{ margin: "5px 5px 0px" }} />
        </a>,
        <span>
          Dilip Chate et al.,{" "}
          <span style={{ fontStyle: "italic" }}>
            Evaluating the Sensitivity of Fine Particulate Matter (PM₂.₅)
            Simulations to Chemical Mechanism in WRF-Chem over Delhi,
            <span style={{ fontWeight: "500" }}> Atmospheric Environment,</span>
          </span>{" "}
          323, 2024
        </span>,
        <a
          href="https://doi.org/10.1016/j.atmosenv.2024.120410"
          target="_blank"
          rel="noreferrer"
        >
          {"Article Link"}
          <RiExternalLinkLine style={{ margin: "5px 5px 0px" }} />
        </a>,
      ]}
    />
    <Section
      titleText=""
      subTitleText="2023"
      description={[
        <span>
          Dilip Chate et al.,{" "}
          <span style={{ fontStyle: "italic" }}>
            Economic Impacts of Air Pollution and Fog in India and Prediction
            Efforts,
            <span style={{ fontWeight: "500" }}>
              {" "}
              Social and Economic Impact of Earth Sciences, Springer,
            </span>
          </span>{" "}
          189-200, 2023
        </span>,
        <a
          href="https://www.doi.org/10.1007/978-981-19-6929-4_10"
          target="_blank"
          rel="noreferrer"
        >
          {"Article Link"}
          <RiExternalLinkLine style={{ margin: "5px 5px 0px" }} />
        </a>,
        <span>
          Dilip Chate et al.,{" "}
          <span style={{ fontStyle: "italic" }}>
            Anthropogenic Aerosols in Precipitation Over the Indo-Gangetic
            Basin,
            <span style={{ fontWeight: "500" }}>
              {" "}
              Environmental Geochemistry and Health,
            </span>
          </span>{" "}
          45(3), 961-980, 2023
        </span>,
        <a
          href="https://www.doi.org/10.1007/s10653-022-01236-6"
          target="_blank"
          rel="noreferrer"
        >
          {"Article Link"}
          <RiExternalLinkLine style={{ margin: "5px 5px 0px" }} />
        </a>,
      ]}
    />
    <Section
      titleText=""
      subTitleText="2022"
      description={[
        <span>
          Dilip Chate et al.,{" "}
          <span style={{ fontStyle: "italic" }}>
            Implications of Implementing Promulgated and Prospective Emission
            Regulations on Air Quality and Health in India During 2030,
            <span style={{ fontWeight: "500" }}>
              {" "}
              Aerosol and Air Quality Research,
            </span>
          </span>{" "}
          22(10), 2022
        </span>,
        <a
          href="https://www.doi.org/10.4209/aaqr.220112"
          target="_blank"
          rel="noreferrer"
        >
          {"Article Link"}
          <RiExternalLinkLine style={{ margin: "5px 5px 0px" }} />
        </a>,
        <span>
          Dilip Chate et al.,{" "}
          <span style={{ fontStyle: "italic" }}>
            Temporal Variability of PM₂.₅ and its Possible Sources at the
            Tropical Megacity, Bengaluru, India,
            <span style={{ fontWeight: "500" }}>
              {" "}
              Environmental Monitoring and Assessment,
            </span>
          </span>{" "}
          194:532, 2022
        </span>,
        <a
          href="https://www.doi.org/10.1007/s10661-022-10235-0"
          target="_blank"
          rel="noreferrer"
        >
          {"Article Link"}
          <RiExternalLinkLine style={{ margin: "5px 5px 0px" }} />
        </a>,
      ]}
    />
    <Section
      titleText=""
      subTitleText="2020"
      description={[
        <span>
          Dilip Chate et al.,{" "}
          <span style={{ fontStyle: "italic" }}>
            Evaluation of PM₂.₅ Forecast Using Chemical Data Assimilation in the
            WRF-Chem Model: A Novel Initiative Under the Ministry of Earth
            Sciences Air Quality Early Warning System for Delhi,
            <span style={{ fontWeight: "500" }}> Current Science,</span>
          </span>{" "}
          118(11), 1-13, 2020
        </span>,
        <a
          href="https://www.currentscience.ac.in/Volumes/118/11/1803.pdf"
          target="_blank"
          rel="noreferrer"
        >
          {"Article Link"}
          <RiExternalLinkLine style={{ margin: "5px 5px 0px" }} />
        </a>,
        <span>
          Dilip Chate et al.,{" "}
          <span style={{ fontStyle: "italic" }}>
            Estimation of Surface Particulate Matter (PM₂.₅ and PM₁₀) Mass
            Concentrations from Ceilometer Backscattered Profiles,
            <span style={{ fontWeight: "500" }}>
              {" "}
              Aerosol and Air Quality Research,
            </span>
          </span>{" "}
          20, 1640-1650, 2020
        </span>,
        <a
          href="https://www.doi.org/10.4209/aaqr.2019.08.0371"
          target="_blank"
          rel="noreferrer"
        >
          {"Article Link"}
          <RiExternalLinkLine style={{ margin: "5px 5px 0px" }} />
        </a>,
      ]}
    />
    <Section
      titleText=""
      subTitleText="2019"
      description={[
        <span>
          Dilip Chate et al.,{" "}
          <span style={{ fontStyle: "italic" }}>
            PM₂.₅, PM₁₀, and Surface Ozone over Lumbini Protected Zone, Nepal,
            During Monsoon Season of 2012,
            <span style={{ fontWeight: "500" }}>
              {" "}
              Journal of Earth System Science,
            </span>
          </span>{" "}
          128(88), 1-13, 2019
        </span>,
        <a
          href="https://www.doi.org/10.1007/s12040-019-1118-5"
          target="_blank"
          rel="noreferrer"
        >
          {"Article Link"}
          <RiExternalLinkLine style={{ margin: "5px 5px 0px" }} />
        </a>,
      ]}
    />
    <Section
      titleText=""
      subTitleText="2018"
      description={[
        <span>
          Dilip Chate et al.,{" "}
          <span style={{ fontStyle: "italic" }}>
            Odd-even Traffic Rule Implementation During Winter 2016 in Delhi did
            not Reduce Traffic Emissions of VOCs, Carbon Dioxide, Methane, and
            Carbon Monoxide,
            <span style={{ fontWeight: "500" }}> Current Science,</span>
          </span>{" "}
          114(6), 1318-1325, 2018
        </span>,
        <a
          href="https://www.jstor.org/stable/26797338"
          target="_blank"
          rel="noreferrer"
        >
          {"Article Link"}
          <RiExternalLinkLine style={{ margin: "5px 5px 0px" }} />
        </a>,
      ]}
    />
    <Section
      titleText=""
      subTitleText="2016"
      description={[
        <span>
          Dilip Chate et al.,{" "}
          <span style={{ fontStyle: "italic" }}>
            Addressing Impacts of Poor Air Quality on Health in India:
            Integrating Air Quality, Health and Meteorological Expertise,
            <span style={{ fontWeight: "500" }}>
              {" "}
              Climate Services for Health: Improving Public Health
              Decision-Making in a New Climate, Case Study 2B, 33-35, WHO/WMO,
            </span>
          </span>{" "}
          Eds. J. Shumake-Guillemot and L Fernandez-Montoya, Geneva, 2016
        </span>,
        <a
          href="https://www.imgw.pl/sites/default/files/2022-06/who-wmo_climate_services_for_health.pdf"
          target="_blank"
          rel="noreferrer"
        >
          {"Article Link"}
          <RiExternalLinkLine style={{ margin: "5px 5px 0px" }} />
        </a>,
      ]}
    />
    <Section
      titleText=""
      subTitleText="2015"
      description={[
        <span>
          Dilip Chate et al.,{" "}
          <span style={{ fontStyle: "italic" }}>
            World Meteorological Organization, Global Atmospheric Watch Report
            217,
            <span style={{ fontWeight: "500" }}>
              {" "}
              System of Air Quality And Forecasting Research (SAFAR-INDIA),
            </span>
          </span>{" "}
          2015
        </span>,
        <a
          href="https://mce2.org/wmogurme/images/reports/GAW_217%20(SAFAR).pdf"
          target="_blank"
          rel="noreferrer"
        >
          {"Article Link"}
          <RiExternalLinkLine style={{ margin: "5px 5px 0px" }} />
        </a>,
      ]}
    />
    <Section
      titleText=""
      subTitleText="2013"
      description={[
        <span>
          Dilip Chate et al.,{" "}
          <span style={{ fontStyle: "italic" }}>
            Radiative Forcing of Black Carbon over Delhi,
            <span style={{ fontWeight: "500" }}>
              {" "}
              International Journal of Photoenergy,
            </span>
          </span>{" "}
          313652, 1-7, 2013
        </span>,
        <a
          href="https://www.doi.org/10.1155/2013/313652"
          target="_blank"
          rel="noreferrer"
        >
          {"Article Link"}
          <RiExternalLinkLine style={{ margin: "5px 5px 0px" }} />
        </a>,
        <span>
          Dilip Chate et al.,{" "}
          <span style={{ fontStyle: "italic" }}>
            Quantifying the Effect of Air Quality Control Measures During the
            2010 Commonwealth Games at Delhi India,
            <span style={{ fontWeight: "500" }}> Atmospheric Environment,</span>
          </span>{" "}
          80, 455-463, 2013
        </span>,
        <a
          href="https://doi.org/10.1016/j.atmosenv.2013.08.012"
          target="_blank"
          rel="noreferrer"
        >
          {"Article Link"}
          <RiExternalLinkLine style={{ margin: "5px 5px 0px" }} />
        </a>,
        <span>
          Dilip Chate et al.,{" "}
          <span style={{ fontStyle: "italic" }}>
            Chemical Characterization of Atmospheric Particulate Matter in
            Delhi, India, Part II: Source Apportionment Studies using PMF₃.₀,
            <span style={{ fontWeight: "500" }}>
              {" "}
              Sustainable Environment Research,
            </span>
          </span>{" "}
          23(5), 295-306, 2013
        </span>,
        <a
          href="https://www.researchgate.net/publication/279716279_Chemical_characterization_of_atmospheric_particulate_matter_in_Delhi_India_part_II_Source_apportionment_studies_using_PMF_30"
          target="_blank"
          rel="noreferrer"
        >
          {"Article Link"}
          <RiExternalLinkLine style={{ margin: "5px 5px 0px" }} />
        </a>,
        <span>
          Dilip Chate et al.,{" "}
          <span style={{ fontStyle: "italic" }}>
            Spatio-Temporal Variation and Deposition of Fine and Coarse
            Particles During Commonwealth Games in Delhi,
            <span style={{ fontWeight: "500" }}>
              {" "}
              Aerosol and Air Quality Research,
            </span>
          </span>{" "}
          13, 748-755, 2013
        </span>,
        <a
          href="https://doi.org/10.4209/aaqr.2012.02.0044"
          target="_blank"
          rel="noreferrer"
        >
          {"Article Link"}
          <RiExternalLinkLine style={{ margin: "5px 5px 0px" }} />
        </a>,
      ]}
    />
    <Section
      titleText=""
      subTitleText="2012"
      description={[
        <span>
          Dilip Chate et al.,{" "}
          <span style={{ fontStyle: "italic" }}>
            Assessments of PM₁, PM₂.₅, and PM₁₀ Concentrations in Delhi at
            Different Mean Cycles,
            <span style={{ fontWeight: "500" }}> GEOFIZIKA,</span>
          </span>{" "}
          29(2), 51-67, 2012
        </span>,
        <a
          href="https://www.researchgate.net/publication/270577693_Assessments_of_PM1_PM25_and_PM10_concentrations_in_Delhi_at_different_mean_cycles"
          target="_blank"
          rel="noreferrer"
        >
          {"Article Link"}
          <RiExternalLinkLine style={{ margin: "5px 5px 0px" }} />
        </a>,
        <span>
          Dilip Chate et al.,{" "}
          <span style={{ fontStyle: "italic" }}>
            Statistical Evaluation of PM₁₀ and Distribution of PM₁, PM₂.₅, and
            PM₁₀ in Ambient Air Due to Extreme Fireworks Episodes in Megacity
            Delhi,
            <span style={{ fontWeight: "500" }}> Natural Hazards,</span>
          </span>{" "}
          61, 521-531, 2012
        </span>,
        <a
          href="https://www.doi.org/10.1007/s11069-011-9931-4"
          target="_blank"
          rel="noreferrer"
        >
          {"Article Link"}
          <RiExternalLinkLine style={{ margin: "5px 5px 0px" }} />
        </a>,
      ]}
    />
    <Section
      titleText=""
      subTitleText="2004"
      description={[
        <span>
          Dilip Chate et al.,{" "}
          <span style={{ fontStyle: "italic" }}>
            Observations on the Characteristics of Aerosols at Traffic Junctions
            in Pune City,
            <span style={{ fontWeight: "500" }}>
              {" "}
              Indian Journal of Radio and Space Physics,
            </span>
          </span>{" "}
          33, 260-266, 2004
        </span>,
        <a
          href="https://www.researchgate.net/publication/249643959_Some_Observations_on_the_Characteristics_of_Aerosols_at_Traffic_Junctions_in_Pune_City"
          target="_blank"
          rel="noreferrer"
        >
          {"Article Link"}
          <RiExternalLinkLine style={{ margin: "5px 5px 0px" }} />
        </a>,
      ]}
    />
    <Section
      titleText="Impacts of Burning Crop Residues"
      subTitleText=""
      description={[
        "Approximately 45 million tons of crop residues are open-burned annually on a global scale. Emission of greenhouse gases and toxic pollutants from burning of crop residues is a serious global concern with severe impacts on the environment and human health.",
        "The influence of emissions from springtime biomass burning on regional ozone in South Asia has been investigated using spatiotemporal deep learning techniques (ConvLSTM and ConvGRU) that can forecast locations of open-burning events up to three days in advance.",
        "These techniques can improve air quality forecasts and thus facilitate the protection and wellbeing of communities. Employing sustainable means for crop residue disposal is crucial for mitigating adverse effects of conventional open-burning practices.",
      ]}
    />
    <Section
      titleText=""
      subTitleText="2024"
      description={[
        <span>
          Dilip Chate et al.,{" "}
          <span style={{ fontStyle: "italic" }}>
            Harnessing Deep Learning for Forecasting Fire-Burning Locations and
            Unveiling PM₂.₅ Emissions,
            <span style={{ fontWeight: "500" }}>
              {" "}
              Modeling Earth Systems and Environment,
            </span>
          </span>{" "}
          10, 927-941, 2024
        </span>,
        <a
          href="https://doi.org/10.1007/s40808-023-01831-1"
          target="_blank"
          rel="noreferrer"
        >
          {"Article Link"}
          <RiExternalLinkLine style={{ margin: "5px 5px 0px" }} />
        </a>,
      ]}
    />
    <Section
      titleText=""
      subTitleText="2015"
      description={[
        <span>
          Dilip Chate et al.,{" "}
          <span style={{ fontStyle: "italic" }}>
            Influence of Springtime Biomass Burning in South Asia on Regional
            Ozone: A Model Based Case Study,
            <span style={{ fontWeight: "500" }}> Atmospheric Environment,</span>
          </span>{" "}
          100, 37-47, 2015
        </span>,
        <a
          href="https://www.doi.org/10.1016/j.atmosenv.2014.10.027"
          target="_blank"
          rel="noreferrer"
        >
          {"Article Link"}
          <RiExternalLinkLine style={{ margin: "5px 5px 0px" }} />
        </a>,
      ]}
    />
    <Section
      titleText="Winter Fog Experiment (WiFEX)"
      subTitleText=""
      description={[
        "The physical and chemical characteristics of fog and meteorological factors responsible for its genesis, sustenance, intensity, and dissipation are poorly understood. Global-level economic losses resulting from radiative and convective fog phenomena are not well documented.",
        "The Winter Fog Experiment (WiFEX) is a project designed to investigate wintertime fog in northern India considering the severe hinderance to flight operations and significant financial losses to the aviation industry resulting from fog-related phemonena.",
        "Extensive sets of ground-based instrumentation have been deployed at Indira Gandhi International Airport, New Delhi. Environmental conditions conducive to fog have been determined by measuring micrometeorological conditions, radiation balance, turbulence, and thermodynamical structure of the surface layer of fog droplets, fog droplet and aerosol microphysics, aerosol optical properties, and aerosol and fog water chemistry.",
        "Since its launch in 2015, results from WiFEX have shown the total economic losses resulting from heavy fog spells at Indira Gandhi International Airport to be approximately 3.9 million USD over a span of 5 years. Insights from the WiFEX study are expected to lead to an improved understanding of radiative and convective fog phenomena and develop reliable forecasting models and observational techniques for accurate prediction of fog events.",
      ]}
    />
    <Section
      titleText=""
      subTitleText="2023"
      description={[
        <span>
          Dilip Chate et al.,{" "}
          <span style={{ fontStyle: "italic" }}>
            WiFEX: Walk Into the Warm Fog Over Indo-Gangetic Plain Region,
            <span style={{ fontWeight: "500" }}>
              {" "}
              Bulletin of the American Meteorological Society,
            </span>
          </span>{" "}
          104(5), E980-E1005, 2023
        </span>,
        <a
          href="https://www.doi.org/10.1175/BAMS-D-21-0197.1"
          target="_blank"
          rel="noreferrer"
        >
          {"Article Link"}
          <RiExternalLinkLine style={{ margin: "5px 5px 0px" }} />
        </a>,
        <span>
          Dilip Chate et al.,{" "}
          <span style={{ fontStyle: "italic" }}>
            Sensitivity of WRF/Chem Simulated PM₂.₅ to Initial/Boundary
            Conditions and Planetary Boundary Layer Parameterization Schemes
            Over the Indo‑Gangetic Plain,
            <span style={{ fontWeight: "500" }}>
              {" "}
              Environmental Monitoring and Assessment,
            </span>
          </span>{" "}
          195, 560, 2023
        </span>,
        <a
          href="https://www.doi.org/10.1007/s10661-023-10987-3"
          target="_blank"
          rel="noreferrer"
        >
          {"Article Link"}
          <RiExternalLinkLine style={{ margin: "5px 5px 0px" }} />
        </a>,
      ]}
    />
    <Section
      titleText=""
      subTitleText="2019"
      description={[
        <span>
          Dilip Chate et al.,{" "}
          <span style={{ fontStyle: "italic" }}>
            WRF Model Sensitivity to Choice of PBL and Microphysics
            Parameterization for an Advection Fog Event at Barkachha, Rural Site
            in the Indo-Gangetic Basin, India,
            <span style={{ fontWeight: "500" }}>
              {" "}
              Theoretical and Applied Climatology,
            </span>
          </span>{" "}
          136, 1099-1113, 2019
        </span>,
        <a
          href="https://www.doi.org/10.1007/s00704-018-2530-5"
          target="_blank"
          rel="noreferrer"
        >
          {"Article Link"}
          <RiExternalLinkLine style={{ margin: "5px 5px 0px" }} />
        </a>,
        <span>
          Dilip Chate et al.,{" "}
          <span style={{ fontStyle: "italic" }}>
            Characterization and Source Identification of PM₂.₅ and its Chemical
            and Carbonaceous Constituents During Winter Fog Experiment 2015-16
            at Indira Gandhi International Airport, Delhi,
            <span style={{ fontWeight: "500" }}>
              {" "}
              Science of the Total Environment,
            </span>
          </span>{" "}
          662, 687-696, 2019
        </span>,
        <a
          href="https://www.doi.org/10.1016/j.scitotenv.2019.01.285"
          target="_blank"
          rel="noreferrer"
        >
          {"Article Link"}
          <RiExternalLinkLine style={{ margin: "5px 5px 0px" }} />
        </a>,
        <span>
          Dilip Chate et al.,{" "}
          <span style={{ fontStyle: "italic" }}>
            WRF Model Prediction of a Dense Fog Event That Occurred During the
            Winter Fog Experiment (WiFEX),
            <span style={{ fontWeight: "500" }}>
              {" "}
              Pure and Applied Geophysics,
            </span>
          </span>{" "}
          176, 1827-1846, 2019
        </span>,
        <a
          href="https://www.doi.org/10.1007/s00024-018-2053-0"
          target="_blank"
          rel="noreferrer"
        >
          {"Article Link"}
          <RiExternalLinkLine style={{ margin: "5px 5px 0px" }} />
        </a>,
        <span>
          Dilip Chate et al.,{" "}
          <span style={{ fontStyle: "italic" }}>
            Two-Way Relationship Between Aerosols and Fog: A Case Study at IGI
            Airport, New Delhi,
            <span style={{ fontWeight: "500" }}>
              {" "}
              Aerosol Air Quality Research,
            </span>
          </span>{" "}
          19, 71-79, 2019
        </span>,
        <a
          href="https://www.doi.org/10.4209/aaqr.2017.11.0542"
          target="_blank"
          rel="noreferrer"
        >
          {"Article Link"}
          <RiExternalLinkLine style={{ margin: "5px 5px 0px" }} />
        </a>,
      ]}
    />
    <Section
      titleText=""
      subTitleText="2016"
      description={[
        <span>
          Dilip Chate et al.,{" "}
          <span style={{ fontStyle: "italic" }}>
            Tethered Balloon-Borne and Ground-Based Measurements of Black Carbon
            and Particulate Profiles Within the Lower Troposphere During the
            Foggy Period in Delhi, India,
            <span style={{ fontWeight: "500" }}>
              {" "}
              Science of the Total Environment,
            </span>
          </span>{" "}
          573, 894-905, 2016
        </span>,
        <a
          href="https://doi.org/10.1016/j.scitotenv.2016.08.185"
          target="_blank"
          rel="noreferrer"
        >
          {"Article Link"}
          <RiExternalLinkLine style={{ margin: "5px 5px 0px" }} />
        </a>,
        <span>
          Dilip Chate et al.,{" "}
          <span style={{ fontStyle: "italic" }}>
            Micrometeorological Investigation of Advection-Radiation Fog over
            Northern India, 2016AGUFM.A23B0222S,
            <span style={{ fontWeight: "500" }}>
              {" "}
              American Geophysical Union,
            </span>
          </span>{" "}
          Fall General Assembly, 2016
        </span>,
      ]}
    />
    <Section
      titleText=""
      subTitleText="2014"
      description={[
        <span>
          Dilip Chate et al.,{" "}
          <span style={{ fontStyle: "italic" }}>
            Impacts of the High Loadings of Primary and Secondary Aerosols on
            Light Extinction at Delhi During Wintertime,
            <span style={{ fontWeight: "500" }}> Atmospheric Environment,</span>
          </span>{" "}
          92, 60-68, 2014
        </span>,
        <a
          href="https://doi.org/10.1016/j.atmosenv.2014.03.064"
          target="_blank"
          rel="noreferrer"
        >
          {"Article Link"}
          <RiExternalLinkLine style={{ margin: "5px 5px 0px" }} />
        </a>,
      ]}
    />
    <Section
      titleText=""
      subTitleText="2004"
      description={[
        <span>
          Dilip Chate et al.,{" "}
          <span style={{ fontStyle: "italic" }}>
            Fog and Precipitation Chemistry at Delhi, North India,
            <span style={{ fontWeight: "500" }}> Atmospheric Environment,</span>
          </span>{" "}
          38, 4215-4222, 2004
        </span>,
        <a
          href="https://www.academia.edu/77735178/Fog_and_precipitation_chemistry_at_Delhi_North_India"
          target="_blank"
          rel="noreferrer"
        >
          {"Article Link"}
          <RiExternalLinkLine style={{ margin: "5px 5px 0px" }} />
        </a>,
      ]}
    />
    <Section
      titleText="Thunderstorm and Lightning Dynamics"
      subTitleText=""
      description={[
        "Studies on global or continent-wide estimations of economic losses due to lightning strikes are sparse despite it being clear that lightning strikes have considerable impacts on economy. Research on lightning climatology over the Indian subcontinent has revealed climatic indicators of lightning flashes and provided useful insights towards mitigation of lightning-related risks and enhancement of disaster resilience for minimizing human and economic losses.",
      ]}
    />
    <Section
      titleText=""
      subTitleText="2022"
      description={[
        <span>
          Dilip Chate et al.,{" "}
          <span style={{ fontStyle: "italic" }}>
            Relationships Among Lightning, Rainfall, and Meteorological
            Parameters over Oceanic and Land Regions of India,
            <span style={{ fontWeight: "500" }}>
              {" "}
              Meteorology and Atmospheric Physics,
            </span>
          </span>{" "}
          134, 1-11, 2022
        </span>,
        <a
          href="https://www.doi.org/10.1007/s00703-021-00841-x"
          target="_blank"
          rel="noreferrer"
        >
          {"Article Link"}
          <RiExternalLinkLine style={{ margin: "5px 5px 0px" }} />
        </a>,
      ]}
    />
    <Section
      titleText=""
      subTitleText="2021"
      description={[
        <span>
          Dilip Chate et al.,{" "}
          <span style={{ fontStyle: "italic" }}>
            Lightning Activity Variability with Prevailing Weather Parameters
            and Aerosol Loading Over Dry and Wet Regions of India,
            <span style={{ fontWeight: "500" }}>
              {" "}
              Pure and Applied Geophysics,
            </span>
          </span>{" "}
          178,1445-1457, 2021
        </span>,
        <a
          href="https://www.doi.org/10.1007/s00703-021-00841-x"
          target="_blank"
          rel="noreferrer"
        >
          {"Article Link"}
          <RiExternalLinkLine style={{ margin: "5px 5px 0px" }} />
        </a>,
        <span>
          Dilip Chate et al.,{" "}
          <span style={{ fontStyle: "italic" }}>
            Relationship of Lightning with Different Weather Parameters During
            Transition Period of Dry to Wet Season Over Indian Region,
            <span style={{ fontWeight: "500" }}>
              {" "}
              Journal of Atmospheric and Solar-Terrestrial Physics,
            </span>
          </span>{" "}
          220, 105673, 1-8, 2021
        </span>,
        <a
          href="https://www.doi.org/10.1016/j.jastp.2021.105673"
          target="_blank"
          rel="noreferrer"
        >
          {"Article Link"}
          <RiExternalLinkLine style={{ margin: "5px 5px 0px" }} />
        </a>,
      ]}
    />
    <Section
      titleText=""
      subTitleText="2019"
      description={[
        <span>
          Dilip Chate et al.,{" "}
          <span style={{ fontStyle: "italic" }}>
            Land-Sea Contrasts for Climatic Lightning Activity over Indian
            Region,
            <span style={{ fontWeight: "500" }}>
              {" "}
              Theoretical and Applied Climatology,
            </span>
          </span>{" "}
          138, 931-940, 2019
        </span>,
        <a
          href="https://www.doi.org/10.1007/s00704-019-02862-4"
          target="_blank"
          rel="noreferrer"
        >
          {"Article Link"}
          <RiExternalLinkLine style={{ margin: "5px 5px 0px" }} />
        </a>,
      ]}
    />
    <Section
      titleText=""
      subTitleText="2018"
      description={[
        <span>
          Dilip Chate et al.,{" "}
          <span style={{ fontStyle: "italic" }}>
            Relationship Between Aerosol and Lightning Over Indo-Gangetic Plain
            (IGP), India,
            <span style={{ fontWeight: "500" }}> Climate Dynamics,</span>
          </span>{" "}
          50, 3865-3884, 2018
        </span>,
        <a
          href="https://www.doi.org/10.1007/s00382-017-3851-2"
          target="_blank"
          rel="noreferrer"
        >
          {"Article Link"}
          <RiExternalLinkLine style={{ margin: "5px 5px 0px" }} />
        </a>,
      ]}
    />
    <Section
      titleText=""
      subTitleText="2017"
      description={[
        <span>
          Dilip Chate et al.,{" "}
          <span style={{ fontStyle: "italic" }}>
            Climate Indicators for Lightning Over Sea, Sea-Land Mixed, and
            Land-Only Surfaces in India,
            <span style={{ fontWeight: "500" }}>
              {" "}
              International Journal of Climatology,
            </span>
          </span>{" "}
          37(4), 1672-1679, 2017
        </span>,
        <a
          href="https://www.doi.org/10.1002/joc.4802"
          target="_blank"
          rel="noreferrer"
        >
          {"Article Link"}
          <RiExternalLinkLine style={{ margin: "5px 5px 0px" }} />
        </a>,
        <span>
          Dilip Chate et al.,{" "}
          <span style={{ fontStyle: "italic" }}>
            Lightning Activity With Rainfall During El-Nino and La-Nina Events
            Over India,
            <span style={{ fontWeight: "500" }}>
              {" "}
              Theoretical and Applied Climatology,
            </span>
          </span>{" "}
          130, 391-400, 2017
        </span>,
        <a
          href="https://www.doi.org/10.1007/s00704-016-1883-x"
          target="_blank"
          rel="noreferrer"
        >
          {"Article Link"}
          <RiExternalLinkLine style={{ margin: "5px 5px 0px" }} />
        </a>,
        <span>
          Dilip Chate et al.,{" "}
          <span style={{ fontStyle: "italic" }}>
            Association of Rainfall and Stability Index with Lightning Parameter
            Over the Indo-Gangetic Plains,
            <span style={{ fontWeight: "500" }}>
              {" "}
              American Journal of Climate Change,
            </span>
          </span>{" "}
          6, 443-454, 2017
        </span>,
        <a
          href="http://www.doi.org/10.4236/ajcc.2017.63023"
          target="_blank"
          rel="noreferrer"
        >
          {"Article Link"}
          <RiExternalLinkLine style={{ margin: "5px 5px 0px" }} />
        </a>,
      ]}
    />
    <Section
      titleText=""
      subTitleText="2018"
      description={[
        <span>
          Dilip Chate et al.,{" "}
          <span style={{ fontStyle: "italic" }}>
            Lightning Activity and its Association With Rainfall and Convective
            Available Potential Energy Over Maharashtra, India,
            <span style={{ fontWeight: "500" }}> Natural Hazards,</span>
          </span>{" "}
          77, 293-304, 2015
        </span>,
        <a
          href="https://www.doi.org/10.1007/s11069-015-1589-x"
          target="_blank"
          rel="noreferrer"
        >
          {"Article Link"}
          <RiExternalLinkLine style={{ margin: "5px 5px 0px" }} />
        </a>,
      ]}
    />
    <Section
      titleText=""
      subTitleText="2018"
      description={[
        <span>
          Dilip Chate et al.,{" "}
          <span style={{ fontStyle: "italic" }}>
            Climatology of Lightning Activity over the Indian Seas,
            <span style={{ fontWeight: "500" }}> Atmosphere-Ocean,</span>
          </span>{" "}
          52(4), 1-7, 2014
        </span>,
        <a
          href="http://dx.doi.org/10.1080/07055900.2014.941323"
          target="_blank"
          rel="noreferrer"
        >
          {"Article Link"}
          <RiExternalLinkLine style={{ margin: "5px 5px 0px" }} />
        </a>,
      ]}
    />
    <Section
      titleText=""
      subTitleText="2018"
      description={[
        <span>
          Dilip Chate et al.,{" "}
          <span style={{ fontStyle: "italic" }}>
            Lightning Activity Over India: A Study of East-West Contrast,
            <span style={{ fontWeight: "500" }}>
              {" "}
              International Journal of Remote Sensing,
            </span>
          </span>{" "}
          34, 5641-5650, 2013
        </span>,
        <a
          href="http://dx.doi.org/10.1080/07055900.2014.941323"
          target="_blank"
          rel="noreferrer"
        >
          {"Article Link"}
          <RiExternalLinkLine style={{ margin: "5px 5px 0px" }} />
        </a>,
      ]}
    />
    <Section
      titleText=""
      subTitleText="2018"
      description={[
        <span>
          Dilip Chate et al.,{" "}
          <span style={{ fontStyle: "italic" }}>
            Parametric Study of Scavenging of Atmospheric Aerosols of Various
            Chemical Species during Thunderstorm and Non-Thunderstorm Rain
            Events,
            <span style={{ fontWeight: "500" }}>
              {" "}
              Journal of Geophysical Research,
            </span>
          </span>{" "}
          110(D23), 2005
        </span>,
        <a
          href="https://agupubs.onlinelibrary.wiley.com/doi/10.1029/2005JD006406"
          target="_blank"
          rel="noreferrer"
        >
          {"Article Link"}
          <RiExternalLinkLine style={{ margin: "5px 5px 0px" }} />
        </a>,
      ]}
    />
    <Section
      titleText=""
      subTitleText="2018"
      description={[
        <span>
          Dilip Chate et al.,{" "}
          <span style={{ fontStyle: "italic" }}>
            Charge Separation Associated with Splashing of Water Drops on Solid
            Surfaces,
            <span style={{ fontWeight: "500" }}> Atmospheric Research,</span>
          </span>{" "}
          29, 115-128, 1993
        </span>,
        <a
          href="https://ui.adsabs.harvard.edu/abs/1993AtmRe..29..115C/abstract"
          target="_blank"
          rel="noreferrer"
        >
          {"Article Link"}
          <RiExternalLinkLine style={{ margin: "5px 5px 0px" }} />
        </a>,
      ]}
    />
    <Section
      titleText="Cruise Campaigns Over the Antarctic and Other Marine Regions"
      subTitleText=""
      description={[
        "Oceanic climate and weather phenomena have a significant impact on regional and global climate. Cruise campaign-based field research has led to improvement in models for predicting and analyzing cyclonic storms. Various implementations of targeted seeding algorithms and prognostic schemes that account for sea-salt sources on the ocean surface have been useful in assessment of oceanic weather dynamics.",
        "Notable statistics:",
        "• Cloud condensation nuclei (CCN) concentration and wind speed (U) show the following empirical correlations for winds above 10 ms⁻¹: log₁₀CCN = 0.057U + 2.4 and log₁₀CCN = 0.047U + 2.6 at super saturation (ss) of 0.6% (R = 0.43) and 0.8% (R = 0.4), respectively.",
        "• Notwithstanding the aerosols in the southwest air mass processed through monsoon clouds and widespread rainfall, the mean CCN concentration of 1038 ± 1046 cm⁻³ remains significant at ss of 0.4% across the Bay of Bengal.",
        "• An average slope of 0.05 cm⁻³m⁻¹s obtained from regression fits for CCN concentrations and wind speed represents the quantification of a climactic controlling factor for strong aerosol sources in tropical marine environments that remains unaffected by the active monsoon-related phenomena over Indian seas.",
      ]}
    />
    <Section
      titleText=""
      subTitleText="2018"
      description={[
        <span>
          Dilip Chate et al.,{" "}
          <span style={{ fontStyle: "italic" }}>
            Cloud Condensation Nuclei over the Bay of Bengal during Indian
            Summer Monsoon,
            <span style={{ fontWeight: "500" }}>
              {" "}
              Advances in Atmospheric Sciences,
            </span>
          </span>{" "}
          35, 218-223, 2018
        </span>,
        <a
          href="https://www.doi.org/10.1007/s00376-017-6331-z"
          target="_blank"
          rel="noreferrer"
        >
          {"Article Link"}
          <RiExternalLinkLine style={{ margin: "5px 5px 0px" }} />
        </a>,
      ]}
    />
    <Section
      titleText=""
      subTitleText="2017"
      description={[
        <span>
          Dilip Chate et al.,{" "}
          <span style={{ fontStyle: "italic" }}>
            Cloud Condensation Nuclei Activity in the Tropical Marine Regions
            during Indian Southwest Monsoon,
            <span style={{ fontWeight: "500" }}>
              {" "}
              Journal of Aerosol Sciences,
            </span>
          </span>{" "}
          114, 276-282, 2017
        </span>,
        <a
          href="https://www.doi.org/10.1016/j.jaerosci.2017.09.022"
          target="_blank"
          rel="noreferrer"
        >
          {"Article Link"}
          <RiExternalLinkLine style={{ margin: "5px 5px 0px" }} />
        </a>,
      ]}
    />
    <Section
      titleText=""
      subTitleText="2012"
      description={[
        <span>
          Dilip Chate et al.,{" "}
          <span style={{ fontStyle: "italic" }}>
            Time-Elapsed Evolution of Aerosols Size Distributions by Snow
            Particles after Passage of Blizzards over the Maitri (Antarctica),
            <span style={{ fontWeight: "500" }}>
              {" "}
              International Journal of Remote Sensing,
            </span>
          </span>{" "}
          33(4), 962-978, 2012
        </span>,
        <a
          href="https://www.doi.org/10.1080/01431161.2010.542206"
          target="_blank"
          rel="noreferrer"
        >
          {"Article Link"}
          <RiExternalLinkLine style={{ margin: "5px 5px 0px" }} />
        </a>,
      ]}
    />
    <Section
      titleText=""
      subTitleText="2010"
      description={[
        <span>
          Dilip Chate et al.,{" "}
          <span style={{ fontStyle: "italic" }}>
            Chemistry of Snow and Lake Water in Antarctic Region,
            <span style={{ fontWeight: "500" }}>
              {" "}
              Journal of the Earth System Science,
            </span>
          </span>{" "}
          119(6), 753-762, 2010
        </span>,
        <a
          href="https://www.doi.org/10.1007/s12040-010-0063-0"
          target="_blank"
          rel="noreferrer"
        >
          {"Article Link"}
          <RiExternalLinkLine style={{ margin: "5px 5px 0px" }} />
        </a>,
      ]}
    />
    <Section
      titleText=""
      subTitleText="2009"
      description={[
        <span>
          Dilip Chate et al.,{" "}
          <span style={{ fontStyle: "italic" }}>
            Sink Mechanism for Significantly Low Level of Ozone over the Arabian
            Sea during Monsoon,
            <span style={{ fontWeight: "500" }}>
              {" "}
              Journal of Geophysical Research,
            </span>
          </span>{" "}
          114(D17), 2009
        </span>,
        <a
          href="https://doi.org/10.1029/2008JD011256"
          target="_blank"
          rel="noreferrer"
        >
          {"Article Link"}
          <RiExternalLinkLine style={{ margin: "5px 5px 0px" }} />
        </a>,
      ]}
    />
    <Section
      titleText=""
      subTitleText="2007"
      description={[
        <span>
          Dilip Chate et al.,{" "}
          <span style={{ fontStyle: "italic" }}>
            Scavenging of Sea-Salt Aerosols by Rain Events over Arabian Sea
            during ARMEX,
            <span style={{ fontWeight: "500" }}> Atmospheric Environment,</span>
          </span>{" "}
          41(32), 6739-6744, 2007
        </span>,
        <a
          href="https://www.doi.org/10.1016/j.atmosenv.2007.04.051"
          target="_blank"
          rel="noreferrer"
        >
          {"Article Link"}
          <RiExternalLinkLine style={{ margin: "5px 5px 0px" }} />
        </a>,
        <span>
          Dilip Chate et al.,{" "}
          <span style={{ fontStyle: "italic" }}>
            Study of Aerosol Transport Through Precipitation Chemistry over
            Arabian Sea during Winter and Summer Monsoon,
            <span style={{ fontWeight: "500" }}> Atmospheric Environment,</span>
          </span>{" "}
          41(4), 825-836, 2007
        </span>,
        <a
          href="https://www.doi.org/10.1016/j.atmosenv.2006.08.049"
          target="_blank"
          rel="noreferrer"
        >
          {"Article Link"}
          <RiExternalLinkLine style={{ margin: "5px 5px 0px" }} />
        </a>,
      ]}
    />
    <Section
      titleText=""
      subTitleText="2005"
      description={[
        <span>
          Dilip Chate et al.,{" "}
          <span style={{ fontStyle: "italic" }}>
            Transport of Ca²⁺ and SO₄²⁻ Aerosols During Southwest Monsoon from
            North African and Gulf Countries Towards India,
            <span style={{ fontWeight: "500" }}> Mausam,</span>
          </span>{" "}
          56, 315-320, 2005
        </span>,
        <a
          href="https://doi.org/10.54302/mausam.v56i1.922"
          target="_blank"
          rel="noreferrer"
        >
          {"Article Link"}
          <RiExternalLinkLine style={{ margin: "5px 5px 0px" }} />
        </a>,
        <span>
          Dilip Chate et al.,{" "}
          <span style={{ fontStyle: "italic" }}>
            Physical and Chemical Characteristics of Aerosols over Arabian Sea
            during ARMEX 2002-2003,
            <span style={{ fontWeight: "500" }}> Mausam,</span>
          </span>{" "}
          56, 293-300, 2005
        </span>,
        <a
          href="https://doi.org/10.54302/mausam.v56i1.920"
          target="_blank"
          rel="noreferrer"
        >
          {"Article Link"}
          <RiExternalLinkLine style={{ margin: "5px 5px 0px" }} />
        </a>,
      ]}
    />
    <Section
      titleText="Effect of Air Pollution on Crop Yields and Impacts of Emission Mitigation"
      subTitleText=""
      description={[
        "High concentrations of air pollutants results in damage to crops, which manifests as plant injury, reduced growth and yield, and premature death. The development and severity of such plant injuries depends on the concentrations of  pollutants and other factors such as the duration of exposure, the species of the plant, its stage of development, and the susceptibility or resistance to injury developed by the plant based on its preconditioning by prior exposure to the pollutant.",
        "Surface ozone (O₃) poses significant global threats to crop production and food security. Globally aggregated yield losses caused due to ozone exposure are 3.6 ± 1.1% for maize, 2.6 ± 0.8% for rice, 6.7 ± 4.1% for soybean, and 7.2 ± 7.3% for wheat.",
        "Crop yield losses due to ozone exposure in India:",
        "• Approximately 2.2 million tons (3.3%) for wheat",
        "• Approximately 2.05 million tons (2.5%) for rice",
        "State-level crop yeild losses:",
        "• 0.61 million tons for wheat and 0.25 million tons for rice in Uttar Pradesh",
        "• 0.49 million tons for wheat in Madhya Pradesh",
        "• 0.23 million tons for wheat in Rajasthan ",
        "• 0.22 million tons for wheat in Maharashtra",
        "• 0.85 million tons for rice in Punjab",
        "• 0.33 million tons for rice in Andhra Pradesh",
        "• 0.23 million tons for rice in West Bengal ",
        "The national aggregate of yield losses for wheat and rice crops is recorded as 5.6 million tons, which makes for approximately 12% of the total amount of cereals required every year for the entire population of the country as specified by the Provision of Food Security Bill (61.2 million tons). The amount of crop yield lost due to ozone pollution can be considered sufficient to feed approximately 94 million poor people (~32% of the total national population) living below the poverty line in India.",
        "Implementation of emission mitigation measures has had significant impacts on improving crop yields. Mitigation of anthropogenic NOₓ pollution has resulted in a relative reduction of 93% (98% for rice and 90% for wheat) in ozone-induced crop yield losses with respect to the baseline scenario. Mitigation of anthropogenic volatile organic compound emissions has similarly resulted in a 24% reduction in ozone-induced crop yield losses. These findings have helped control emission of precursors of ozone and other pollutants.",
      ]}
    />
    <Section
      titleText=""
      subTitleText="2018"
      description={[
        <span>
          Dilip Chate et al.,{" "}
          <span style={{ fontStyle: "italic" }}>
            Air Pollutants Concern in Field Crops Under Changing Environment
            Scenarios,
            <span style={{ fontWeight: "500" }}>
              {" "}
              Journal of Agrometeorology,
            </span>
          </span>{" "}
          20, 302-306, 2018
        </span>,
        <a
          href="https://www.doi.org/10.1310/RG.2.2.12815.28324"
          target="_blank"
          rel="noreferrer"
        >
          {"Article Link"}
          <RiExternalLinkLine style={{ margin: "5px 5px 0px" }} />
        </a>,
      ]}
    />
    <Section
      titleText=""
      subTitleText="2016"
      description={[
        <span>
          Dilip Chate et al.,{" "}
          <span style={{ fontStyle: "italic" }}>
            Impact of Emission Mitigation on Ozone Induced Wheat and Rice Damage
            in India,
            <span style={{ fontWeight: "500" }}> Current Science,</span>
          </span>{" "}
          110(8), 1452-1458, 2016
        </span>,
        <a
          href="https://www.currentscience.ac.in/Volumes/110/08/1452.pdf"
          target="_blank"
          rel="noreferrer"
        >
          {"Article Link"}
          <RiExternalLinkLine style={{ margin: "5px 5px 0px" }} />
        </a>,
      ]}
    />
    <Section
      titleText=""
      subTitleText="2014"
      description={[
        <span>
          Dilip Chate et al.,{" "}
          <span style={{ fontStyle: "italic" }}>
            Reductions in India's Crop Yield due to Ozone,
            <span style={{ fontWeight: "500" }}>
              {" "}
              Geophysical Research Letters,
            </span>
          </span>{" "}
          41/15, 5685-5691, 2014
        </span>,
        <a
          href="https://doi.org/10.1002/2014GL060930"
          target="_blank"
          rel="noreferrer"
        >
          {"Article Link"}
          <RiExternalLinkLine style={{ margin: "5px 5px 0px" }} />
        </a>,
      ]}
    />
    <Section
      titleText="Tropospheric Photochemical Processes"
      subTitleText=""
      description={[
        "Concentration levels of ozone (O₃) have increased throughout the troposphere over the past century due to increasing emissions of volatile organic compounds and NOₓ species. Ground-level O₃ continues to be a major environmental problem having severe impacts on human health and vegetation. Current scientific research on photochemically-formed ultrafine particulate pollutants has proven useful in developing strategies to mitigate the impact of VOC, NOₓ, and O₃ pollutants on the global economy.",
        "The lifetime of ozone ranges from a few hours at surface level in polluted urban areas to a few months in the upper troposphere. The global mean lifetime of ozone is estimated to be 25 days, which allows for it to be transported over regional as well as intercontinental scales. Most ground-level O₃ is produced by photochemical processing of air pollutants by ultraviolet (UV) rays. The net production of O₃ in the troposphere results from the combined action of UV-A and UV-B radiation. This occurs via photo-dissociation of nitrogen dioxide to nitric oxide and oxygen, mainly by UV-A radiation, and subsequent reaction of oxygen atoms with molecular oxygen.",
        "Statistics from studies on tropospheric photochemical processes over the Indian subcontinent:",
        "New particle formation, as observed at two distinct urban locations in the Indian sub-continent, was shown to have a lower frequency (14%) over a North Indian megacity than that over Pune (26%), a megacity in western India, an effect that can be attributed to the presence of preexisting large particles.",
        "New particle bursts in nucleation mode (size 5–25 nm) followed by a growth in size by condensation had infrequent occurrences (5 out of 79 days) at a tropical South Indian site during the months of May to July.",
      ]}
    />
    <Section
      titleText=""
      subTitleText="2022"
      description={[
        <span>
          Dilip Chate et al.,{" "}
          <span style={{ fontStyle: "italic" }}>
            Analysis of Ozone Photochemistry over Southern Tropical Megacity,
            Bengaluru, India,
            <span style={{ fontWeight: "500" }}>
              {" "}
              Photochemistry and Photobiology,
            </span>
          </span>{" "}
          98(6) 1312-1322, 2022
        </span>,
        <a
          href="https://doi.org/10.1111/php.13626"
          target="_blank"
          rel="noreferrer"
        >
          {"Article Link"}
          <RiExternalLinkLine style={{ margin: "5px 5px 0px" }} />
        </a>,
        <span>
          Dilip Chate et al.,{" "}
          <span style={{ fontStyle: "italic" }}>
            Comprehensive Modeling of Seasonal Variation of Surface Ozone over
            Southern Tropical City, Bengaluru, India,
            <span style={{ fontWeight: "500" }}>
              {" "}
              Nature Environment and Pollution Technology,
            </span>
          </span>{" "}
          21(3), 1269-1277, 2022
        </span>,
        <a
          href="https://doi.org/10.46488/NEPT.2022.v21i03.033"
          target="_blank"
          rel="noreferrer"
        >
          {"Article Link"}
          <RiExternalLinkLine style={{ margin: "5px 5px 0px" }} />
        </a>,
        <span>
          Dilip Chate et al.,{" "}
          <span style={{ fontStyle: "italic" }}>
            Variation of Ozone, Carbon Monoxide and Oxides of Nitrogen at
            Bengaluru, India,
            <span style={{ fontWeight: "500" }}>
              {" "}
              Journal of Scientific Research,
            </span>
          </span>{" "}
          14(2), 459-470, 2022
        </span>,
        <a
          href="https://www.doi.org/10.3329/jsr.v14i2.55626"
          target="_blank"
          rel="noreferrer"
        >
          {"Article Link"}
          <RiExternalLinkLine style={{ margin: "5px 5px 0px" }} />
        </a>,
      ]}
    />
    <Section
      titleText=""
      subTitleText="2021"
      description={[
        <span>
          Dilip Chate et al.,{" "}
          <span style={{ fontStyle: "italic" }}>
            Variability of Ozone and Oxides of Nitrogen in the Tropical City,
            Bengaluru, India,
            <span style={{ fontWeight: "500" }}>
              {" "}
              Environmental Monitoring and Assessment,
            </span>
          </span>{" "}
          193(12):844, 2021
        </span>,
        <a
          href="https://www.doi.org/10.1007/s10661-021-09635-5"
          target="_blank"
          rel="noreferrer"
        >
          {"Article Link"}
          <RiExternalLinkLine style={{ margin: "5px 5px 0px" }} />
        </a>,
      ]}
    />
    <Section
      titleText=""
      subTitleText="2014"
      description={[
        <span>
          Dilip Chate et al.,{" "}
          <span style={{ fontStyle: "italic" }}>
            Deviations from the O₃-NO-NO₂ Photo-Stationary State in Delhi,
            India,
            <span style={{ fontWeight: "500" }}> Atmospheric Environment,</span>
          </span>{" "}
          96, 353–358, 2014
        </span>,
        <a
          href="https://doi.org/10.1016/j.atmosenv.2014.07.054"
          target="_blank"
          rel="noreferrer"
        >
          {"Article Link"}
          <RiExternalLinkLine style={{ margin: "5px 5px 0px" }} />
        </a>,
        <span>
          Dilip Chate et al.,{" "}
          <span style={{ fontStyle: "italic" }}>
            Air Pollution, Air Quality, and Climate Change,
            <span style={{ fontWeight: "500" }}> Advances in Meteorology,</span>
          </span>{" "}
          2014
        </span>,
        <a
          href="http://dx.doi.org/10.1155/2014/983426"
          target="_blank"
          rel="noreferrer"
        >
          {"Article Link"}
          <RiExternalLinkLine style={{ margin: "5px 5px 0px" }} />
        </a>,
        <span>
          Dilip Chate et al.,{" "}
          <span style={{ fontStyle: "italic" }}>
            Estimation of the Lifetime of Nitrogen Oxides over India using
            SCIAMACHY Observations,
            <span style={{ fontWeight: "500" }}>
              {" "}
              International Journal of Remote Sensing,
            </span>
          </span>{" "}
          1244-1252, 2014
        </span>,
        <a
          href="https://doi.org/10.1080/01431161.2013.873146"
          target="_blank"
          rel="noreferrer"
        >
          {"Article Link"}
          <RiExternalLinkLine style={{ margin: "5px 5px 0px" }} />
        </a>,
      ]}
    />
    <Section
      titleText=""
      subTitleText="2010"
      description={[
        <span>
          Dilip Chate et al.,{" "}
          <span style={{ fontStyle: "italic" }}>
            Volatile Properties of Atmospheric Aerosols During Nucleation Events
            at Pune, India,
            <span style={{ fontWeight: "500" }}>
              {" "}
              Journal of Earth System Science,
            </span>
          </span>{" "}
          120, 347-357, 2011
        </span>,
        <a
          href="https://doi.org/10.1007/s12040-011-0072-7"
          target="_blank"
          rel="noreferrer"
        >
          {"Article Link"}
          <RiExternalLinkLine style={{ margin: "5px 5px 0px" }} />
        </a>,
      ]}
    />
    <Section
      titleText=""
      subTitleText="2009"
      description={[
        <span>
          Dilip Chate et al.,{" "}
          <span style={{ fontStyle: "italic" }}>
            Generation and Growth of Aerosols over Pune, India,
            <span style={{ fontWeight: "500" }}> Atmospheric Environment,</span>
          </span>{" "}
          43(4), 820-828, 2009
        </span>,
        <a
          href="https://doi.org/10.1016/j.atmosenv.2008.10.051"
          target="_blank"
          rel="noreferrer"
        >
          {"Article Link"}
          <RiExternalLinkLine style={{ margin: "5px 5px 0px" }} />
        </a>,
      ]}
    />
    <Section
      titleText=""
      subTitleText="2005"
      description={[
        <span>
          Dilip Chate et al.,{" "}
          <span style={{ fontStyle: "italic" }}>
            Growth Properties of Sub-Micron Aerosols during Cold Season in
            India,
            <span style={{ fontWeight: "500" }}>
              {" "}
              Aerosol and Air Quality Research,
            </span>
          </span>{" "}
          5(2): 127-140, 2005
        </span>,
        <a
          href="https://doi.org/10.4209/aaqr.2005.12.0001"
          target="_blank"
          rel="noreferrer"
        >
          {"Article Link"}
          <RiExternalLinkLine style={{ margin: "5px 5px 0px" }} />
        </a>,
      ]}
    />
    <Section
      titleText=""
      subTitleText="2005"
      description={[
        <span>
          Dilip Chate et al.,{" "}
          <span style={{ fontStyle: "italic" }}>
            Surface Ozone Measurements over Himalayan Region and Delhi, North
            India,
            <span style={{ fontWeight: "500" }}>
              {" "}
              Indian Journal of Radio and Space Physics,
            </span>
          </span>{" "}
          33, 391-398, 2004
        </span>,
        <a
          href="http://nopr.niscpr.res.in/handle/123456789/19344"
          target="_blank"
          rel="noreferrer"
        >
          {"Article Link"}
          <RiExternalLinkLine style={{ margin: "5px 5px 0px" }} />
        </a>,
      ]}
    />
    <Section
      titleText="Parameterization schemes for storm simulations"
      subTitleText=""
      description={[
        "Global disaster risk statistics indicate that since 1980, over 2.5 million people have been affected by natural disasters worldwide. Adjusted for inflation, the total damages from these disasters increased from $52 billion per year in the 1980s to $212 billion per year in the last decade, reaching $228 billion in the first three years of the 2020s. Between 1970 and 2021, extreme weather events have caused approximately 12,000 disasters worldwide.",
        "The Hurricane and Aerosol Microphysics Program funded by NOAA, CIRA, CSU, and Woodley Weather Consultants involves hurricane mitigation research focused on predicting hurricane intensity based on impacts of aerosols and microphysics. An empirical scheme linking scavenging coefficients to rainfall rates has yielded improved models that have been tested in interactive-nested-multi-grid frameworks.",
      ]}
    />
    <Section
      titleText=""
      subTitleText="2019"
      description={[
        <span>
          Dilip Chate et al.,{" "}
          <span style={{ fontStyle: "italic" }}>
            Evidence of Precedent Wind Role on Controlling PM₁ Wet Scavenging of
            Aerosols During Monsoon Rain Events,
            <span style={{ fontWeight: "500" }}> Atmospheric Environment,</span>
          </span>{" "}
          201, 265-277, 2019
        </span>,
        <a
          href="https://www.doi.org/10.1016/j.atmosenv.2018.12.041"
          target="_blank"
          rel="noreferrer"
        >
          {"Article Link"}
          <RiExternalLinkLine style={{ margin: "5px 5px 0px" }} />
        </a>,
      ]}
    />
    <Section
      titleText=""
      subTitleText="2017"
      description={[
        <span>
          Dilip Chate et al.,{" "}
          <span style={{ fontStyle: "italic" }}>
            Sedimentary and Geomorphic Signatures of a Cloud Burst and Triggered
            Flash Floods in the Indus Valley of Ladakh, Himalaya,
            <span style={{ fontWeight: "500" }}> Himalayan Geology,</span>
          </span>{" "}
          38(1), 12-29, 2017
        </span>,
        <a
          href="https://www.tropmet.res.in/~lip/Publication/p-2017.html"
          target="_blank"
          rel="noreferrer"
        >
          {"Article Link"}
          <RiExternalLinkLine style={{ margin: "5px 5px 0px" }} />
        </a>,
        <span>
          Dilip Chate et al.,{" "}
          <span style={{ fontStyle: "italic" }}>
            Seasonal Variation of Urban Heat Island and its Impact on
            Air-Quality using SAFAR Observations at Delhi, India,
            <span style={{ fontWeight: "500" }}>
              {" "}
              American Journal of Climate Change,
            </span>
          </span>{" "}
          6(2), 294-305, 2017
        </span>,
        <a
          href="https://www.doi.org/10.4236/ajcc.2017.62015"
          target="_blank"
          rel="noreferrer"
        >
          {"Article Link"}
          <RiExternalLinkLine style={{ margin: "5px 5px 0px" }} />
        </a>,
        <span>
          Dilip Chate et al.,{" "}
          <span style={{ fontStyle: "italic" }}>
            Diurnal Evolution of Urban Heat Island and its Impact on Air Quality
            by using Ground Observations (SAFAR) over New Delhi,
            <span style={{ fontWeight: "500" }}>
              {" "}
              Open Journal of Air Pollution,
            </span>
          </span>{" "}
          6(2), 52-64, 2017
        </span>,
        <a
          href="https://www.doi.org/10.4236/ojap.2017.62005"
          target="_blank"
          rel="noreferrer"
        >
          {"Article Link"}
          <RiExternalLinkLine style={{ margin: "5px 5px 0px" }} />
        </a>,
      ]}
    />
    <Section
      titleText=""
      subTitleText="2016"
      description={[
        <span>
          Dilip Chate et al.,{" "}
          <span style={{ fontStyle: "italic" }}>
            Nature and Sources of Ionic Species in Precipitation Across the
            Indo-Gangetic Plains, India,
            <span style={{ fontWeight: "500" }}>
              {" "}
              Aerosol and Air Quality Research,
            </span>
          </span>{" "}
          16(4), 943-957, 2016
        </span>,
        <a
          href="https://doi.org/10.4209/aaqr.2015.06.0423"
          target="_blank"
          rel="noreferrer"
        >
          {"Article Link"}
          <RiExternalLinkLine style={{ margin: "5px 5px 0px" }} />
        </a>,
      ]}
    />
    <Section
      titleText=""
      subTitleText="2015"
      description={[
        <span>
          Dilip Chate et al.,{" "}
          <span style={{ fontStyle: "italic" }}>
            Inter-comparison of Different NOₓ Emission Inventories and
            Associated Variation in Simulated Surface Ozone in Indian Region,
            <span style={{ fontWeight: "500" }}> Atmospheric Environment,</span>
          </span>{" "}
          117, 61-73, 2015
        </span>,
        <a
          href="https://www.doi.org/10.1016/j.atmosenv.2015.06.057"
          target="_blank"
          rel="noreferrer"
        >
          {"Article Link"}
          <RiExternalLinkLine style={{ margin: "5px 5px 0px" }} />
        </a>,
      ]}
    />
    <Section
      titleText=""
      subTitleText="2014"
      description={[
        <span>
          Dilip Chate et al.,{" "}
          <span style={{ fontStyle: "italic" }}>
            Lidar Profiling of Aerosol Scavenging Parameters at a Tropical
            Station, Pune, India,
            <span style={{ fontWeight: "500" }}>
              {" "}
              International Journal of Remote Sensing,
            </span>
          </span>{" "}
          35(5), 1956–1965, 2014
        </span>,
        <a
          href="http://www.doi.org/10.1080/01431161.2014.882527"
          target="_blank"
          rel="noreferrer"
        >
          {"Article Link"}
          <RiExternalLinkLine style={{ margin: "5px 5px 0px" }} />
        </a>,
      ]}
    />
    <Section
      titleText=""
      subTitleText="2012"
      description={[
        <span>
          Dilip Chate et al.,{" "}
          <span style={{ fontStyle: "italic" }}>
            Rainwater Chemistry in the North Western Himalayan Region, India
            <span style={{ fontWeight: "500" }}> Atmospheric Research,</span>
          </span>{" "}
          (104-105), 128-138, 2012
        </span>,
        <a
          href="https://doi.org/10.1016/j.atmosres.2011.09.006"
          target="_blank"
          rel="noreferrer"
        >
          {"Article Link"}
          <RiExternalLinkLine style={{ margin: "5px 5px 0px" }} />
        </a>,
        <span>
          Dilip Chate et al.,{" "}
          <span style={{ fontStyle: "italic" }}>
            Pre- and Post-Monsoon Variations in the Magnetic Susceptibilities of
            Soils of Mumbai Metropolitan Region: Implications to Surface
            Redistribution of Urban Soils Loaded with Anthropogenic Particulates
            <span style={{ fontWeight: "500" }}>
              {" "}
              Environmental Earth Sciences,
            </span>
          </span>{" "}
          67, 813-831, 2012
        </span>,
        <a
          href="https://www.doi.org/10.1007/s12665-012-1528-z"
          target="_blank"
          rel="noreferrer"
        >
          {"Article Link"}
          <RiExternalLinkLine style={{ margin: "5px 5px 0px" }} />
        </a>,
      ]}
    />
    <Section
      titleText=""
      subTitleText="2011"
      description={[
        <span>
          Dilip Chate et al.,{" "}
          <span style={{ fontStyle: "italic" }}>
            Below-Cloud Rain Scavenging of Atmospheric Aerosols for Aerosol
            Deposition Models,
            <span style={{ fontWeight: "500" }}> Atmospheric Research,</span>
          </span>{" "}
          99(3-4): 528–536, 2011
        </span>,
        <a
          href="https://doi.org/10.1016/j.atmosres.2010.12.010"
          target="_blank"
          rel="noreferrer"
        >
          {"Article Link"}
          <RiExternalLinkLine style={{ margin: "5px 5px 0px" }} />
        </a>,
      ]}
    />
    <Section
      titleText=""
      subTitleText="2009"
      description={[
        <span>
          Dilip Chate et al.,{" "}
          <span style={{ fontStyle: "italic" }}>
            Acidity of Raindrop by Uptake of Gases and Aerosol Pollutants,
            <span style={{ fontWeight: "500" }}> Atmospheric Environment,</span>
          </span>{" "}
          43(8), 1571-1577, 2009
        </span>,
        <a
          href="https://doi.org/10.1016/j.atmosenv.2008.06.031"
          target="_blank"
          rel="noreferrer"
        >
          {"Article Link"}
          <RiExternalLinkLine style={{ margin: "5px 5px 0px" }} />
        </a>,
      ]}
    />
    <Section
      titleText=""
      subTitleText="2006"
      description={[
        <span>
          Dilip Chate et al.,{" "}
          <span style={{ fontStyle: "italic" }}>
            Study of Collision Efficiencies of Water Drops, Scavenging
            Coefficients, and Evolutions of Atmospheric Aerosol Size
            Distribution by Rain Event,
            <span style={{ fontWeight: "500" }}> Ph.D. Thesis,</span>
          </span>{" "}
          2006
        </span>,
        <a
          href="https://www.tropmet.res.in/24-D.%20M.%20Chate-scientist_detail"
          target="_blank"
          rel="noreferrer"
        >
          {"Article Link"}
          <RiExternalLinkLine style={{ margin: "5px 5px 0px" }} />
        </a>,
      ]}
    />
    <Section
      titleText=""
      subTitleText="2005"
      description={[
        <span>
          Dilip Chate et al.,{" "}
          <span style={{ fontStyle: "italic" }}>
            Study of Scavenging of Submicron-Sized Aerosol Particles by
            Thunderstorm Rain Events,
            <span style={{ fontWeight: "500" }}> Atmospheric Environment,</span>
          </span>{" "}
          39(35), 6608-6619, 2005
        </span>,
        <a
          href="https://doi.org/10.1016/j.atmosenv.2005.07.063"
          target="_blank"
          rel="noreferrer"
        >
          {"Article Link"}
          <RiExternalLinkLine style={{ margin: "5px 5px 0px" }} />
        </a>,
        <span>
          Dilip Chate et al.,{" "}
          <span style={{ fontStyle: "italic" }}>
            Variation in the Chemistry of Aerosols in two Different Winter
            Seasons at Pune and Sinhagad, India,
            <span style={{ fontWeight: "500" }}>
              {" "}
              Aerosol and Air Quality Research,
            </span>
          </span>{" "}
          5(1), 115-126, 2005
        </span>,
        <a
          href="https://doi.org/10.4209/aaqr.2005.06.0008"
          target="_blank"
          rel="noreferrer"
        >
          {"Article Link"}
          <RiExternalLinkLine style={{ margin: "5px 5px 0px" }} />
        </a>,
        <span>
          Dilip Chate et al.,{" "}
          <span style={{ fontStyle: "italic" }}>
            Study of Chemical Composition of Rainwater at an Urban (Pune) and a
            Rural (Sinhagad) Location in India,
            <span style={{ fontWeight: "500" }}>
              {" "}
              Journal of Geophysical Research,
            </span>
          </span>{" "}
          110(D8), 1-10, 2005
        </span>,
        <a
          href="https://www.doi.org/10.1029/2004jd004789"
          target="_blank"
          rel="noreferrer"
        >
          {"Article Link"}
          <RiExternalLinkLine style={{ margin: "5px 5px 0px" }} />
        </a>,
        <span>
          Dilip Chate et al.,{" "}
          <span style={{ fontStyle: "italic" }}>
            Growth Properties of Sub-Micron Aerosols during Cold Season in
            India,
            <span style={{ fontWeight: "500" }}>
              {" "}
              Aerosol and Air Quality Research,
            </span>
          </span>{" "}
          5(2): 127-140, 2005
        </span>,
        <a
          href="https://doi.org/10.4209/aaqr.2005.12.0001"
          target="_blank"
          rel="noreferrer"
        >
          {"Article Link"}
          <RiExternalLinkLine style={{ margin: "5px 5px 0px" }} />
        </a>,
      ]}
    />
    <Section
      titleText=""
      subTitleText="2004"
      description={[
        <span>
          Dilip Chate et al.,{" "}
          <span style={{ fontStyle: "italic" }}>
            Field Studies of Scavenging of Aerosols by Rain Events,
            <span style={{ fontWeight: "500" }}>
              {" "}
              Journal of Aerosol Science,
            </span>
          </span>{" "}
          35(6), 695-706, 2004
        </span>,
        <a
          href="https://doi.org/10.1016/j.jaerosci.2003.09.007"
          target="_blank"
          rel="noreferrer"
        >
          {"Article Link"}
          <RiExternalLinkLine style={{ margin: "5px 5px 0px" }} />
        </a>,
        <span>
          Dilip Chate et al.,{" "}
          <span style={{ fontStyle: "italic" }}>
            Field Measurements of Sub-Micron Aerosol Concentrations During Cold
            Season in India,
            <span style={{ fontWeight: "500" }}> Current Science,</span>
          </span>{" "}
          86(12), 1610-1613, 2004
        </span>,
        <a
          href="https://www.jstor.org/stable/24108012"
          target="_blank"
          rel="noreferrer"
        >
          {"Article Link"}
          <RiExternalLinkLine style={{ margin: "5px 5px 0px" }} />
        </a>,
        <span>
          Dilip Chate et al.,{" "}
          <span style={{ fontStyle: "italic" }}>
            Variation in the Concentrations of Ionic Species in Rain Events at
            Pune,
            <span style={{ fontWeight: "500" }}>
              {" "}
              Indian Journal of Radio and Space Physics,
            </span>
          </span>{" "}
          33, 185-188, 2004
        </span>,
        <a
          href="http://nopr.niscpr.res.in/handle/123456789/25748"
          target="_blank"
          rel="noreferrer"
        >
          {"Article Link"}
          <RiExternalLinkLine style={{ margin: "5px 5px 0px" }} />
        </a>,
        <span>
          Dilip Chate et al.,{" "}
          <span style={{ fontStyle: "italic" }}>
            Chemical Composition of Precipitation During 1984-2002 at Pune,
            India,
            <span style={{ fontWeight: "500" }}> 38(12), 1705-1714, 2004</span>
          </span>{" "}
          5(2): 127-140, 2005
        </span>,
        <a
          href="https://doi.org/10.1016/j.atmosenv.2003.12.016"
          target="_blank"
          rel="noreferrer"
        >
          {"Article Link"}
          <RiExternalLinkLine style={{ margin: "5px 5px 0px" }} />
        </a>,
      ]}
    />
    <Section
      titleText=""
      subTitleText="2003"
      description={[
        <span>
          Dilip Chate et al.,{" "}
          <span style={{ fontStyle: "italic" }}>
            Scavenging of Aerosols and their Chemical Species by Rain,
            <span style={{ fontWeight: "500" }}> Atmospheric Environment,</span>
          </span>{" "}
          37(18), 2477-2484, 2003
        </span>,
        <a
          href="https://doi.org/10.1016/S1352-2310(03)00162-6"
          target="_blank"
          rel="noreferrer"
        >
          {"Article Link"}
          <RiExternalLinkLine style={{ margin: "5px 5px 0px" }} />
        </a>,
      ]}
    />
    <Section
      titleText=""
      subTitleText="1997"
      description={[
        <span>
          Dilip Chate et al.,{" "}
          <span style={{ fontStyle: "italic" }}>
            Collection Efficiencies of Large Water Drops Collecting Aerosol
            Particles of Various Densities,
            <span style={{ fontWeight: "500" }}> Atmospheric Environment,</span>
          </span>{" "}
          31(11), 1631-1635, 1997
        </span>,
        <a
          href="https://doi.org/10.1016/S1352-2310(96)00338-X"
          target="_blank"
          rel="noreferrer"
        >
          {"Article Link"}
          <RiExternalLinkLine style={{ margin: "5px 5px 0px" }} />
        </a>,
      ]}
    />
  </section>
);

const Profile: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const routePage = location.pathname;

  const [pageTitle, setPageTitle] = useState("");
  const [textContainer, setTextContainer] = useState(<section>{""}</section>);

  const navigator = (page: string) => {
    switch (page) {
      case "bio":
        navigate("/dr_dilip_chate");
        break;
      case "res":
        navigate("/research");
        break;
      case "pub":
        navigate("/publications");
        break;
      default:
        navigate("/profile");
        break;
    }
  };

  useEffect(() => {
    switch (routePage) {
      case "/profile":
        setPageTitle("Dr. Dilip Chate");
        setTextContainer(profileDescription);
        break;
      case "/dr_dilip_chate":
        setPageTitle("Dr. Dilip Chate");
        setTextContainer(profileDescription);
        break;
      case "/research":
        setPageTitle("Dr. Dilip Chate | Research");
        setTextContainer(researchDescription);
        break;
      case "/publications":
        setPageTitle("Dr. Dilip Chate | Publications");
        setTextContainer(publicationsDescription);
        break;
      default:
        setPageTitle("Dr. Dilip Chate");
        setTextContainer(profileDescription);
        break;
    }
  }, [routePage]);

  return (
    <React.Fragment>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <section
        style={{
          display: "flex",
          flexDirection: "column",
          flexWrap: "wrap",
          alignItems: "center",
          margin: "20px",
          borderRadius: "20px",
          border: "3px solid rgba(26, 21, 21, 0.744)",
          outline: "1px solid rgb(20, 16, 16, 0.7)",
          backgroundColor: "#22262F",
        }}
      >
        <article
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <article
            style={{
              maxWidth: "160px",
            }}
          >
            <img src={logo} alt="logo" style={{ width: "100%" }} />
          </article>

          <h1 style={{ padding: "4px 6px" }}>Dr. Dilip Chate</h1>
        </article>
        <section className={classes.headerContainer}>
          <article
            style={{
              display: "flex",
              flexWrap: "wrap",
              flexDirection: "row",
              zIndex: "7",
            }}
          >
            <button
              className={classes.menuButton}
              style={{
                borderRadius: "20px",
                background:
                  routePage !== "/research" && routePage !== "/publications"
                    ? "blue"
                    : "none",
              }}
              onClick={() => navigator("bio")}
            >
              Profile
            </button>
            <button
              className={classes.menuButton}
              style={{
                borderRadius: "20px",
                background: routePage === "/research" ? "blue" : "none",
              }}
              onClick={() => navigator("res")}
            >
              Research
            </button>
            <button
              className={classes.menuButton}
              style={{
                borderRadius: "20px",
                background: routePage === "/publications" ? "blue" : "none",
              }}
              onClick={() => navigator("pub")}
            >
              Publications
            </button>
          </article>
        </section>
        {routePage !== "/research" && routePage !== "/publications" && (
          <img
            src={lecture}
            alt="description img"
            style={{
              width: "70%",
              margin: "20px",
              border: "6px solid rgba(26, 21, 21, 0.744)",
              borderRadius: "20px",
              outline: "4px solid rgb(20, 16, 16, 0.7)",
            }}
          />
        )}
        {routePage === "/research" && (
          <img
            src={fieldwork}
            alt="description img"
            style={{
              width: "70%",
              margin: "20px",
              border: "6px solid rgba(26, 21, 21, 0.744)",
              borderRadius: "20px",
              outline: "4px solid rgb(20, 16, 16, 0.7)",
            }}
          />
        )}
        {routePage === "/publications" && (
          <img
            src={publications}
            alt="description img"
            style={{
              width: "70%",
              margin: "20px",
              border: "6px solid rgba(26, 21, 21, 0.744)",
              borderRadius: "20px",
              outline: "4px solid rgb(20, 16, 16, 0.7)",
            }}
          />
        )}
      </section>
      <Container>{textContainer}</Container>
      <Footer />
    </React.Fragment>
  );
};

export default Profile;
